import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import styled from "styled-components"

const Intro = styled.div`
  padding-top: 10rem;

  h2 {
    font-size: 6rem;
  }

  p {
    font-size: 3rem;
  }

  .intro-text {
    font-size: 2rem;
  }

  @media screen and (max-width: 500px) {
    h2 {
      font-size: 4rem;
    }

    p {
      font-size: 2rem;
    }
  }
`

const IndexPage = () => {
  return (
    <div>
      <SEO title="Home" />
      <Layout>
        <Intro>
          <h2>Jonathan J. Rasmussen</h2>
          <p className="intro-text">
            Professional software developer based out of Seattle, Washington
          </p>
        </Intro>
      </Layout>
    </div>
  )
}

export default IndexPage
